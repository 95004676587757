<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} CAR TYPE</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"/>
    </div>
    <vs-divider class="mb-0"/>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">


        <!-- CATEGORY NAME -->
        <label for="categoriesList">Category</label>
        <select v-model="form.specs_category_id" id="categoriesList">
          <option :value="item.id" v-for="(item, index) in categories" :key="index">{{item.name_en}}</option>
        </select>


        <!--        <vs-select-->
        <!--          class="selectExample"-->
        <!--          label="Specs Category"-->
        <!--          :v-model="form.specs_category_id"-->
        <!--        >-->
        <!--          <vs-select-item :key="index" :value="item.id" :text="item.name_en" v-for="(item,index) in categories"/>-->
        <!--        </vs-select>-->
        <!--        <vs-input label="Specs Category" v-model="form.specs_category_id" class="mt-5 w-full" name="specs_category_id"/>-->
        <span class="text-danger text-sm" v-show="errors.has('specs_category_id')">{{ errors.first('specs_category_id') }}</span>

        <!-- English NAME -->
        <vs-input label="English Name" v-model="form.name_en" class="mt-5 w-full" name="name_en"/>
        <span class="text-danger text-sm" v-show="errors.has('name_en')">{{ errors.first('name_en') }}</span>

        <!-- Arabic NAME -->
        <vs-input label="Arabic Name" v-model="form.name_ar" class="mt-5 w-full" name="name_ar"/>
        <span class="text-danger text-sm" v-show="errors.has('name_ar')">{{ errors.first('name_ar') }}</span>

        <label for="type">Type</label>
        <select v-model="form.type" id="type" @change="log">
          <option value="0" >CheckBox</option>
          <option value="1">Text</option>
        </select>

        <div class="mt-5 w-full">
          <label>Show on Mobile</label>
          <vs-switch v-model="form.mobile_active"/>
        </div>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      }
    },
    data() {
      return {
        categories: [],
        form: {
          name_en: "",
          name_ar: "",
          mobile_active: false,
          type: '',
          specs_category_id: "",
          boolean: true,
          text: '',
          selectVar: ''
        },
        id: null,
        settings: {
          maxScrollbarLength: 150,
          wheelSpeed: .80,
        },
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return;
        if (Object.entries(this.data).length === 0) {
          this.initValues();
          this.$validator.reset()
        } else {


          let {id, name_en, name_ar, mobile_active, type, specs_category_id} = JSON.parse(JSON.stringify(this.data));

          let mappedType;
          if(type === 'bool'){
            // debugger
            mappedType = 0;
          }else if (type === 'text'){
            // debugger
            mappedType = 1;
          }

          this.id = id;
          this.form = {
            name_en,
            name_ar,
            mobile_active,
            specs_category_id,
            type: mappedType
          };
          this.initValues()
        }
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar');
            this.$validator.reset();
            this.initValues()
          }
        }
      },
      isFormValid() {
        return !this.errors.any() && this.form.name_en && this.form.name_ar && this.form.type && this.form.specs_category_id;
      }
    },
    methods: {
      log(){

      },
      // getSpecsCategory(id) {
      //   this.$vs.loading();
      //   this.$httpCars
      //     .get(`/specs-categories?${id}`)
      //     .then(res => {
      //       this.$vs.loading.close();
      //       console.log(res.data.name_en)
      //       return res.data.name_en
      //     })
      //     .catch(() => {
      //       this.$vs.loading.close();
      //       this.$vs.notify({
      //         title: "Something Error 🙁",
      //         text: "Couldn't fetch data from the server",
      //         color: 'danger'
      //       });
      //     })
      // },
      initValues() {
        if (this.data.id) return;
        this.id = null;
        this.form = {
          name_en: "",
          name_ar: "",
          mobile_active: false,
          type: "",
          specs_category_id: ""
        };
        this.id = null;
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          this.$vs.loading();
          if (result) {
            const obj = this.form;

            const formData = new FormData();

            formData.append("name_en", obj.name_en);
            formData.append("name_ar", obj.name_ar);
            formData.append("type", obj.type);
            formData.append("specs_category_id", obj.specs_category_id);
            formData.append("mobile_active", Number(obj.mobile_active));

            if (this.id !== null && this.id >= 0) {
              this.$httpCars.post("/specs/" + this.id, formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }).then(() => {
                const message = "Specifications has been updated successfully";
                this.$vs.notify({
                  title: "Successful operation",
                  text: message,
                  color: "success"
                });
                this.$emit('onSuccess');
              }).catch(() => {
                //console.log({err});

                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error",
                  text: "Couldn't add data to the server",
                  color: 'danger'
                });
              }).finally(() => {
                this.$emit('closeSidebar');
                this.initValues();
                this.$vs.loading.close();
              });
            } else {
              this.$httpCars.post("/specs", formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }).then(() => {
                const message = "Specifications has been created successfully";
                this.$vs.notify({
                  title: "Successful operation",
                  text: message,
                  color: "success"
                });
                this.$emit('onSuccess');
              }).catch(() => {
                //console.log({err});

                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error 🙁",
                  text: "Couldn't add data to the server",
                  color: 'danger'
                });
              }).finally(() => {
                this.$emit('closeSidebar');
                this.initValues();
                this.$vs.loading.close();
              });
            }

            this.$emit('closeSidebar');
            this.initValues();
            this.$vs.loading.close();
          }
        })
      },
      getCategories() {
        this.$vs.loading();
        this.$httpCars
          .get(`/specs-categories`)
          .then(res => {
            this.$vs.loading();
            this.$httpCars
              .get(`/specs-categories?per_page=${res.data.data.total}`)
              .then(res => {
                this.$vs.loading.close();
                this.categories = res.data.data.data;
              })
              .catch(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error",
                  text: "Couldn't fetch data from the server",
                  color: 'danger'
                });
              })
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error",
              text: "Couldn't fetch data from the server",
              color: 'danger'
            });
          })
      }
    },
    components: {
      VuePerfectScrollbar,
    },
    mounted() {
      this.getCategories();
    },
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }

  label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
  }

  select {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
  }

</style>
